import React from "react"
import {Link} from "gatsby";

import Layout from "../components/layout"
import ServiceContainer from "../components/service-container";
import Gallery from "../components/gallery";
import GallerySlider from "../components/gallery-slider"
import ScrollButton from "../components/scroll-button"

import useWindowSize from '../hooks/useWindowSize';

import banner from "../images/Main/bani-winter.webp";

import { chan } from "../consts/descriptionBanya";
import { chanGallery } from "../consts/descriptionBanya";
// import { useAuthValue } from "../components/Auth/AuthContext";

const DescriptionChan = () => {

  /**Получаем статус пользователя*/
  // const { currentUser } = useAuthValue();

  const size = useWindowSize();

  return (
    <Layout
      metaContent="Банный чан представляет собой большую чашу, изготовленную из металла. Внутри емкость обшита деревом. Установлен он на улице возле бани. Принимать ванны в чане можно круглый год. Вода подогревается печью на дровах до 35-45 градусов в зависимости от температуры воздуха."
      title="Затонские бани - чан"
      keywords="баня с чаном барнаул, баня в барнауле с чаном на улице, бани с чаном Затон"
    >
      {/**Банннер слайдер*/}
      <ScrollButton/>
      <div className='banner-container'>
        <div className="site-Banner">
          <img src="https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_1980,q_90,f_webp/v1694675704/main_page/photo-229_1_fyhrfq.webp" alt='Баня по-черному' className="banner-img"/>
          <div className="Banner-details">
            <h1>Чан</h1>
            <span className="banner-adress"><i className="fa fa-map-marker-alt"/>{' '}п. Затон ул.Водников 1е</span><br/>
            {/*{*/}
            {/*  currentUser ? (*/}
                <Link to="/order/">Заказать</Link>
              {/*) : (*/}
              {/*  <a href="http://localhost:3000/">Заказать</a>*/}
              {/*)*/}
            {/*}*/}
          </div>
        </div>
      </div>
      {/**Блоки с галлереями фотографий*/}
      <ServiceContainer
        title="Назначение"
        description={chan}
      >
        {
          size.width >= 768
            ?
            <Gallery gallery={chanGallery} />
            :
            <GallerySlider gallery={chanGallery}/>
        }
      </ServiceContainer>
    </Layout>
  )
}
export default DescriptionChan